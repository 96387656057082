.btn{
    appearance: none;
    border: none;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    font-family: 'Eina01-Regular',sans-serif;
    font-weight: 600;
    color: $text;
    &:hover{
        background-color: #e8e9eb;
    }
    &:focus{
        border: none;
        outline: none;
    }
    &.btn-main{
        background-color: $green_main;
        color: #fff;
        font-size: 1em;
        padding: 1em 1.5em;
        &:hover,&:focus{
            background-color: $green_dark;
        }
    }
}
.input-group{
    width: 100%;
    .error{
        color: #f00;
        font-size: 14px;
    }
}
.form-control{
    border: 1px solid $border;
    border-radius: 0.5rem;
    padding: 0.5rem 0.666rem;
    box-sizing: border-box;
    width: 100%;
    &:focus{
       
        outline: none;
    }
}

::-webkit-input-placeholder {
     color: #72767E;
     font-family: 'Eina01-Regular',sans-serif;
     font-size: 18px;
        line-height: normal;
}

::-moz-placeholder {
    color: #72767E;
    font-family: 'Eina01-Regular',sans-serif;
    font-size: 18px;
    line-height: 25px;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #72767E;
    font-family: 'Eina01-Regular',sans-serif;
    line-height: 25px;
    font-size: 18px;
}

:-moz-placeholder {
    color: #72767E;
    font-family: 'Eina01-Regular',sans-serif;
    line-height: 25px;
    font-size  : 18px;
    opacity: 1;
}

:focus {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}

.ElementsApp,
.ElementsApp .InputElement{
    font-size: 1rem !important;
    color: #72767E !important;
    font-family: 'Eina01-Regular' !important;
}