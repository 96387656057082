@import "variables";
@import "colors";
@import "mixins";
@import "modal";

//components
@import "components/components";

// pages
@import "pages/pages";

.section-intro .row .col .intro_slide li {margin:0 auto}