/* The Modal (background) */
.modal { display: none; position: fixed; z-index: 10; left: 0; top: 0; width: 100%; height: 100%; overflow: auto; background: rgba(114, 118, 126, 0.8); }

.modal-content { position: relative; background-color: #fefefe; padding: 25px 47px; border-radius: 18px; -webkit-box-shadow: 0px 4px 8px #72767E; box-shadow: 0px 4px 8px #72767E; -webkit-animation-name: animatetop; -webkit-animation-duration: 0.4s; animation-name: animatetop; animation-duration: 0.4s; max-width: 772px; margin: 0 auto; position: absolute; left: 23px; right: 20px; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); }

.modal-close-btn { width: 15px; }

.close { float: right; background-image: url("../images/close-modal.svg"); background-repeat: no-repeat; width: 15px; height: 15px; }

.close:hover, .close:focus { color: #000; text-decoration: none; cursor: pointer; }

.modal-header { margin-bottom: 18px; }

.modal-header h2 { font-size: 15px; color: #343538; margin: 0px; }

.modal-body p { font-size: 15px; line-height: 22px; color: #343538; margin: 0px; }

@media (max-width: 992px) { .modal-content { padding-left: 25px; padding-right: 23px; } }

html.modal-open { overflow: hidden; }

@font-face { font-family: 'Eina01-Regular'; src: url("../fonts/eina/Eina01-Light.eot"); src: url("../fonts/eina/Eina01-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/eina/Eina01-Light.woff2") format("woff2"), url("../fonts/eina/Eina01-Light.woff") format("woff"), url("../fonts/eina/Eina01-Light.svg#Eina01-Light") format("svg"); font-weight: 300; font-style: normal; }

@font-face { font-family: 'Eina01-Regular'; src: url("../fonts/eina/Eina01-SemiBold.eot"); src: url("../fonts/eina/Eina01-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/eina/Eina01-SemiBold.woff2") format("woff2"), url("../fonts/eina/Eina01-SemiBold.woff") format("woff"), url("../fonts/eina/Eina01-SemiBold.svg#Eina01-SemiBold") format("svg"); font-weight: 600; font-style: normal; }

@font-face { font-family: 'Eina01-Regular'; src: url("../fonts/eina/Eina01-Regular.eot"); src: url("../fonts/eina/Eina01-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/eina/Eina01-Regular.woff2") format("woff2"), url("../fonts/eina/Eina01-Regular.woff") format("woff"), url("../fonts/eina/Eina01-Regular.svg#Eina01-Regular") format("svg"); font-weight: normal; font-style: normal; }

@font-face { font-family: 'Eina01-Regular'; src: url("../fonts/eina/Eina01-Bold.eot"); src: url("../fonts/eina/Eina01-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/eina/Eina01-Bold.woff2") format("woff2"), url("../fonts/eina/Eina01-Bold.woff") format("woff"), url("../fonts/eina/Eina01-Bold.svg#Eina01-Bold") format("svg"); font-weight: bold; font-style: normal; }

html { font-size: 18px; font-family: 'Eina01-Regular', sans-serif; color: #434343; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; }

@-moz-document url-prefix() { body { font-weight: lighter !important; } }

p { font-size: 18px; line-height: 26px; }

h1, h2, h3, h4, h5 { font-family: 'Eina01-Regular',sans-serif; font-weight: bold; margin-bottom: 1em; margin-top: 0.75em; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { h1, h2, h3, h4, h5 { font-family: 'Eina01-Regular', sans-serif; font-weight: bold; letter-spacing: -0.01em; } }

@-moz-document url-prefix() { h1, h2, h3, h4, h5 { font-family: 'Eina01-Regular',sans-serif; font-weight: bold; letter-spacing: -0.01em; } }

h1 { font-size: 68px; line-height: 84px; }

@media screen and (max-width: 767px) { h1 { font-size: 31px; line-height: 42px; } }

h3 { font-size: 50px; line-height: 72px; }

@media screen and (max-width: 768px) { h3 { font-size: 1rem; line-height: 1.5rem; } }

h4 { font-size: 32px; line-height: 40px; }

@media screen and (max-width: 768px) { h4 { font-size: 1rem; line-height: 1.5rem; } }

h5 { font-size: 24px; line-height: 32px; }

@media screen and (max-width: 768px) { h5 { font-size: 1rem; line-height: 1.5rem; } }

.bold, strong, b { font-weight: bold; }

a { color: #0aad0a; text-decoration: none; }

a:hover { color: #098a09; }

body, html { margin: 0; padding: 0; overflow-X: hidden; }

div { -webkit-box-sizing: border-box; box-sizing: border-box; }

img { border: none; }

.container { width: 100%; max-width: 1200px; padding-left: 10px; padding-right: 10px; position: relative; margin: 0 auto; }

@media screen and (max-width: 1280px) { .container { padding-left: 16px; padding-right: 16px; } }

.container .row { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }

@media screen and (max-width: 992px) { .container .row { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; } }

.container .col { -webkit-box-flex: 0; -ms-flex: 0 0 50%; flex: 0 0 50%; }

@media screen and (max-width: 992px) { .container .col { -webkit-box-flex: 0; -ms-flex: 0 0 100%; flex: 0 0 100%; width: 100%; } }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; max-width: 1140px; }

.slick-prev:before, .slick-next:before { content: ''; }

.slick-prev, .slick-next { background-repeat: no-repeat; background-position: center; background-size: contain; }

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { background-repeat: no-repeat; background-position: center; background-size: contain; }

.slick-prev { background-image: url(../images/slick-prev.svg); }

.slick-prev:hover, .slick-prev:focus { background-image: url(../images/slick-prev.svg); }

.slick-next { background-image: url(../images/slick-next.svg); }

.slick-next:hover, .slick-next:focus { background-image: url(../images/slick-next.svg); }

.btn { -webkit-appearance: none; -moz-appearance: none; appearance: none; border: none; border-radius: 0.5rem; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; cursor: pointer; -webkit-box-sizing: border-box; box-sizing: border-box; font-family: 'Eina01-Regular',sans-serif; font-weight: 600; color: #434343; }

.btn:hover { background-color: #e8e9eb; }

.btn:focus { border: none; outline: none; }

.btn.btn-main { background-color: #0aad0a; color: #fff; font-size: 1em; padding: 1em 1.5em; }

.btn.btn-main:hover, .btn.btn-main:focus { background-color: #098a09; }

.input-group { width: 100%; }

.input-group .error { color: #f00; font-size: 14px; }

.form-control { border: 1px solid #c7c8cd; border-radius: 0.5rem; padding: 0.5rem 0.666rem; -webkit-box-sizing: border-box; box-sizing: border-box; width: 100%; }

.form-control:focus { outline: none; }

::-webkit-input-placeholder { color: #72767E; font-family: 'Eina01-Regular',sans-serif; font-size: 18px; line-height: normal; }

::-moz-placeholder { color: #72767E; font-family: 'Eina01-Regular',sans-serif; font-size: 18px; line-height: 25px; opacity: 1; }

:-ms-input-placeholder { color: #72767E; font-family: 'Eina01-Regular',sans-serif; line-height: 25px; font-size: 18px; }

:-moz-placeholder { color: #72767E; font-family: 'Eina01-Regular',sans-serif; line-height: 25px; font-size: 18px; opacity: 1; }

:focus { outline: none; }

::-moz-focus-inner { border: 0; }

.ElementsApp, .ElementsApp .InputElement { font-size: 1rem !important; color: #72767E !important; font-family: 'Eina01-Regular' !important; }

.w-100 { width: 100%; -webkit-box-flex: 0 !important; -ms-flex: 0 0 100% !important; flex: 0 0 100% !important; }

.text-center { text-align: center; }

.d-none { display: none !important; }

.d-flex { display: -webkit-box; display: -ms-flexbox; display: flex; }

@media screen and (max-width: 768px) { .hide-xs { display: none !important; } }

@media screen and (min-width: 768px) { .show-xs { display: none !important; } }

@media screen and (max-width: 992px) { .hide-md { display: none !important; } }

@media screen and (min-width: 993px) { .hide-tab-above { display: none !important; } }

@media screen and (min-width: 1281px) { .hide-lg { display: none !important; } }

.footer-main-wrapper hr { border-top: 1px solid #BDBDBD; margin-top: 0; margin-bottom: 38px; }

.footer-main-wrapper .mobile-version { display: none; }

.footer-main-wrapper .text-for-disabled { margin-top: 16px; margin-bottom: 30px; font-size: 12px; line-height: 140.62%; color: #343538; }

.footer-main-wrapper .text-for-disabled .contact-no { color: #343538; }

.footer-main-wrapper .copyright { margin-top: 28.1px; margin-bottom: 20px; }

.footer-main-wrapper .copyright small { margin-right: 32px; }

.footer-main-wrapper .copyright small a { color: #72767E; font-size: 10px; font-weight: 600; }

.footer-main-wrapper .social-media-icon-sec a img { margin-right: 14.71px; }

.footer-main-wrapper .footer-col-links { max-width: 140px; margin-right: 60px; }

.footer-main-wrapper .footer-col { max-width: 280px; margin-right: 80px; }

.footer-main-wrapper .essential-nav dt { margin-bottom: 18px; font-weight: 700; color: #343538; }

.footer-main-wrapper .essential-nav dd { margin-bottom: 16px; margin-left: 0px; }

.footer-main-wrapper .essential-nav dd a { font-size: 14px; color: #424242; font-weight: 600; }

.footer-main-wrapper .footer-nav { list-style: none; padding: 0; }

.footer-main-wrapper .footer-nav .footer-nav-link { margin-left: 0px; margin-bottom: 17px; }

.footer-main-wrapper .footer-nav .footer-nav-link a { color: #424242; font-weight: 600; }

.footer-main-wrapper .footer-nav .nav-link-last { margin-bottom: 30px; }

@media (max-width: 1191px) { .footer-main-wrapper .footer-col-links { max-width: 130px; margin-right: 40px; }
  .footer-main-wrapper .footer-col { max-width: 280px; margin-right: 80px; } }

@media (max-width: 1071px) { .footer-main-wrapper .copyright { margin-bottom: 0px; }
  .footer-main-wrapper .text-for-disabled { margin-top: 0px; font-size: 10px; }
  .footer-main-wrapper .footer-col-links { max-width: 130px; margin-right: 30px; }
  .footer-main-wrapper .footer-col { max-width: 280px; margin-right: 40px; } }

@media (max-width: 992px) { .footer-main-wrapper .text-for-disabled { color: #424242; }
  .footer-main-wrapper .copyright { margin-top: 0px; }
  .footer-main-wrapper dl { margin: 0px 0px 50px 0px; }
  .footer-main-wrapper .footer-row-copyright { padding-left: 20px; }
  .footer-main-wrapper small { width: 100%; margin-bottom: 18px; display: block; }
  .footer-main-wrapper small a { color: #343538 !important; }
  .footer-main-wrapper .copyright { margin-bottom: 0px; }
  .footer-main-wrapper .small-last-child { margin-bottom: 10px !important; }
  .footer-main-wrapper .footer-col { max-width: 100%; }
  .footer-main-wrapper .footer-nav, .footer-main-wrapper .footer-col-links { margin-left: 20px; margin-right: 0px; }
  .footer-main-wrapper .footer-nav-link a { font-size: 14px; }
  .footer-main-wrapper dt { font-size: 14px; margin-bottom: 9px !important; }
  .footer-main-wrapper dd { margin-bottom: 14px !important; }
  .footer-main-wrapper dd a { font-size: 12px !important; }
  .footer-main-wrapper .mobile-version { display: inline-block; }
  .footer-main-wrapper .social-media-icon-sec { float: right; margin-top: 8px; }
  .footer-main-wrapper .desktop-version { display: none; }
  .footer-main-wrapper .footer-row { -webkit-box-orient: inherit; -webkit-box-direction: inherit; -ms-flex-direction: inherit; flex-direction: inherit; }
  .footer-main-wrapper .footer-logo img { width: 111px; } }

body.stickyOn { margin-top: 50px; }

@media screen and (min-width: 769px) { body.stickyOn { margin-top: 75px; } }

.header { padding-top: 13px; padding-bottom: 14px; -webkit-box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16); box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16); background: #fff; }

@media screen and (max-width: 768px) { .header { padding-top: 8px; padding-bottom: 8px; } }

.header .btn-main { padding: 0.85em 1em; max-width: 178px; margin-left: auto; height: 48px; line-height: 48px; border-radius: 8px; }

@media screen and (max-width: 768px) { .header .btn-main { height: 34px; max-width: 121px; } }

.header .container .row { -ms-flex-wrap: nowrap; flex-wrap: nowrap; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; }

.header .container .row .col { -webkit-box-flex: 0; -ms-flex: 0 0 calc(100% - 75px); flex: 0 0 calc(100% - 75px); }

.header .container .row .col.logo-col { -webkit-box-flex: 0; -ms-flex: 0 0 55px; flex: 0 0 55px; margin-right: 20px; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { .header .container .row .col.logo-col { -webkit-box-flex: 0; -ms-flex: 0 0 50%; flex: 0 0 50%; margin-right: 0px; } }

.header .container .row .col.logo-col a { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; height: 100%; }

.header .container .row .col.logo-col img { width: 42px; margin-top: auto; margin-bottom: 2px; }

@media screen and (max-width: 768px) { .header .container .row .col.logo-col img { width: 22.36px; height: 24.91px; margin-bottom: 5px; } }

.header.sticky { position: fixed; top: 0; left: 0; right: 0; z-index: 9; -webkit-animation: sticky 0.7s forwards; animation: sticky 0.7s forwards; }

.header.sticky-hide { -webkit-animation: sticky-hide 0.7s forwards; animation: sticky-hide 0.7s forwards; }

@-webkit-keyframes sticky { 0% { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
  100% { -webkit-transform: translateY(0%); transform: translateY(0%); } }

@keyframes sticky { 0% { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
  100% { -webkit-transform: translateY(0%); transform: translateY(0%); } }

@-webkit-keyframes sticky-hide { 0% { -webkit-transform: translateY(0%); transform: translateY(0%); }
  100% { -webkit-transform: translateY(-100%); transform: translateY(-100%); } }

@keyframes sticky-hide { 0% { -webkit-transform: translateY(0%); transform: translateY(0%); }
  100% { -webkit-transform: translateY(-100%); transform: translateY(-100%); } }

.section { padding-top: 120px; padding-bottom: 120px; position: relative; }

@media screen and (max-width: 992px) { .section { padding-top: 60px; padding-bottom: 60px; } }

.section-intro { padding-top: 58px; padding-bottom: 0px; }

@media screen and (max-width: 992px) { .section-intro .container { padding: 0; }
  .section-intro .container h3, .section-intro .container h4, .section-intro .container h5, .section-intro .container p, .section-intro .container .img-col { padding-left: 10px; padding-right: 10px; } }

.section-intro .row { -ms-flex-wrap: nowrap; flex-wrap: nowrap; }

.section-intro .row .col { -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; }

@media screen and (min-width: 993px) { .section-intro .row .col { max-width: 650px; } }

.section-intro .row .col.intro_img { position: relative; margin-left: auto; }

@media screen and (min-width: 993px) { .section-intro .row .col.intro_img { max-width: calc(100% - 650px); } }

.section-intro .row .col.intro_img_hero { position: relative; width: 100%; margin-left: auto; }

@media screen and (min-width: 993px) { .section-intro .row .col.intro_img_hero { max-width: calc(100% - 650px); } }

.section-intro .row .col .intro_slide { margin: 0; padding: 0; display: -webkit-box; display: -ms-flexbox; display: flex; position: relative; width: 100%; }

@media screen and (min-width: 993px) { .section-intro .row .col .intro_slide { max-width: 478px; } }

.section-intro .row .col .intro_slide .slick-arrow { opacity: 0; -webkit-transition: all ease 0.5s; transition: all ease 0.5s; }

.section-intro .row .col .intro_slide:hover .slick-arrow { opacity: 1; }

.section-intro .row .col .intro_slide li { float: left; display: block; position: relative; }

@media screen and (min-width: 993px) { .section-intro .row .col .intro_slide li { max-width: 478px; } }

.section-intro .row .col .intro_slide li .hero_text { position: absolute; background: rgba(22, 22, 22, 0.4); bottom: 30px; padding: 10px 30px 20px; color: #fff; font-size: 12px; line-height: 18px; font-weight: 600; }

@media screen and (max-width: 768px) { .section-intro .row .col .intro_slide li .hero_text { padding: 10px 18px 20px; } }

.section-intro .row .col .intro_slide li img { width: 100%; }

@media screen and (min-width: 993px) { .section-intro .row .col .intro_slide li img { border-radius: 10px; } }

@media screen and (max-width: 992px) { .section-intro { padding-top: 20px; padding-bottom: 50px; } }

@media screen and (max-width: 992px) { .section-intro .row { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; } }

.section-intro p { color: #343538; }

@media screen and (min-width: 993px) { .section-intro p { max-width: 576px; } }

.section-intro .intro_title { margin-top: 0px; margin-bottom: 25px; font-size: 56px; line-height: 64px; }

@media screen and (max-width: 768px) { .section-intro .intro_title { font-size: 24px; line-height: 40px; max-width: 425px; }
  .section-intro .intro_title br { display: none; } }

.section-intro .intro_subtitle { margin-top: 15px; margin-bottom: 45px; }

@media screen and (max-width: 768px) { .section-intro .intro_subtitle { margin-bottom: 30px; font-size: 18px; line-height: 32px; } }

.section-intro h5 { margin-bottom: 10px; }

@media screen and (max-width: 768px) { .section-intro h5 { margin-bottom: 20px; margin-top: 40px; font-size: 24px; line-height: 32px; } }

.section-intro .intro-img { max-width: 530px; width: 100%; margin-bottom: 40px; }

.section-intro .intro_img { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: start; -ms-flex-align: start; align-items: start; }

.section-intro .intro_img img { width: 100%; }

@media screen and (min-width: 993px) { .section-intro .intro_img img { max-width: 478px; margin-left: auto; border-radius: 12px; } }

.section-intro .img-col { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; }

@media screen and (max-width: 992px) { .section-intro .img-col { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; margin-bottom: 30px; } }

.section-buildings { padding-top: 84px; padding-bottom: 0px; }

@media screen and (max-width: 768px) { .section-buildings { display: none; } }

.section-buildings .col { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: end; -ms-flex-align: end; align-items: flex-end; }

.section-buildings img.buildings { max-width: 975px; margin: 0 auto; }

.section-instagram { padding-top: 50px; padding-bottom: 75px; background-color: #ecf6ff; }

@media screen and (max-width: 992px) { .section-instagram { padding-bottom: 44px; } }

@media screen and (max-width: 992px) { .section-instagram .container { padding-right: 0; } }

@media screen and (max-width: 992px) { .section-instagram { padding-bottom: 40px; } }

.section-instagram .instafeed h4 { text-align: center; }

@media screen and (max-width: 425px) { .section-instagram .instafeed h4 { max-width: 320px; margin-left: auto; margin-right: auto; } }

@media screen and (min-width: 993px) { .section-instagram .instafeed { padding-left: 20px; padding-right: 20px; } }

.section-instagram .instafeed_list { margin: 0; padding: 0; position: relative; list-style: none; }

.section-instagram .instafeed_list .slick-list { width: 100%; }

@media screen and (max-width: 992px) { .section-instagram .instafeed_list .slick-list { padding-right: 33%; } }

@media screen and (min-width: 993px) { .section-instagram .instafeed_list { display: -webkit-box; display: -ms-flexbox; display: flex; } }

.section-instagram .instafeed_list li { -webkit-box-sizing: border-box; box-sizing: border-box; width: 25%; display: block; float: left; }

.section-instagram .instafeed_list li:focus { outline: none; }

.section-instagram .instafeed_list li .img-contain { padding-left: 10px; padding-right: 10px; }

.section-instagram .instafeed_list li img { max-width: 100%; border-radius: 12px; }

@media screen and (max-width: 992px) { .section-instagram .instafeed_list li { width: 50%; } }

.section-instagram .container:after { content: ''; height: 1px; bottom: 0; left: 16px; right: 16px; position: absolute; background: #434343; display: none; }

.section-donate { padding-top: 20px; padding-bottom: 20px; background: #ecf6ff; }

@media screen and (max-width: 992px) { .section-donate { padding-top: 0px; } }

.section-donate:after { position: absolute; left: 0; right: 0; bottom: 0; content: ''; display: block; height: 80px; background: #ecf6ff; z-index: -1; }

@media screen and (max-width: 992px) { .section-donate:after { height: 70vw; } }

@media screen and (max-width: 768px) { .section-donate:after { min-height: 690px; } }

.section-donate h2.donate_title { font-size: 48px; line-height: 72px; margin-bottom: 30px; color: #343538; margin-right: auto; margin-left: auto; font-family: 'Eina01-Regular'; font-weight: 600; }

@media screen and (max-width: 768px) { .section-donate h2.donate_title { font-size: 30px; line-height: 38px; margin-bottom: 20px; } }

.section-donate h3.donate_title { font-size: 32px; line-height: 40px; margin-bottom: 50px; max-width: 600px; margin-right: auto; margin-left: auto; color: #343538; }

@media screen and (max-width: 768px) { .section-donate h3.donate_title { font-size: 24px; line-height: 32px; margin-bottom: 20px; } }

.section-donate .donate_form { background: #fff; max-width: 978px; margin: 0 auto; -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.27); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.27); border-radius: 20px; padding: 30px 20px; -webkit-box-sizing: border-box; box-sizing: border-box; display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-pack: distribute; justify-content: space-around; }

.section-donate .donate_form .main_donate .form-control { margin-top: 10px !important; }

.section-donate .donate_form .main_donate .amount_selector + .amount_selector { margin-top: 27px; }

.section-donate .donate_form form { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; height: 100%; }

.section-donate .donate_form form button.btn.btn-primary.btn-block.mt-4 { width: 100%; max-width: 208px; height: 56px; margin: 0 auto; padding: .85em; border-radius: 12px; background-color: #0aad0a; color: #fff; font-size: 1em; margin-top: auto; margin-bottom: 12px; }

@media screen and (max-width: 992px) { .section-donate .donate_form form button.btn.btn-primary.btn-block.mt-4 { margin-top: 20px; } }

@media screen and (max-width: 992px) { .section-donate .donate_form { -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16); -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; } }

.section-donate .donate_form .form-col { -webkit-box-flex: 1; -ms-flex: 1 1 50%; flex: 1 1 50%; max-width: 390px; padding-left: 15px; padding-right: 15px; position: relative; -webkit-box-sizing: border-box; box-sizing: border-box; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }

@media screen and (max-width: 992px) { .section-donate .donate_form .form-col { -webkit-box-flex: 1; -ms-flex: 1 1 100%; flex: 1 1 100%; padding-left: 4px; padding-right: 4px; max-width: 336px; width: 100%; margin: 0 auto; min-height: 365px; } }

@media screen and (max-width: 768px) { .section-donate .donate_form .form-col { -webkit-box-flex: 1; -ms-flex: 1 1 100%; flex: 1 1 100%; padding-left: 4px; padding-right: 4px; } }

.section-donate .donate_form .form_items.form-col { padding-left: 35px; padding-right: 35px; }

@media screen and (min-width: 993px) { .section-donate .donate_form .form_items.form-col { min-width: 450px; } }

@media screen and (min-width: 993px) and (-ms-high-contrast: active), screen and (min-width: 993px) and (-ms-high-contrast: none) { .section-donate .donate_form .form_items.form-col { min-width: auto; } }

@media screen and (max-width: 992px) { .section-donate .donate_form .form_items.form-col { margin-bottom: 25px; padding-left: 0px; padding-right: 0px; } }

@media screen and (min-width: 993px) { .section-donate .donate_form .form_items.form-col:after { position: absolute; right: -20px; top: 0; bottom: 20px; width: 1px; background: #BDBDBD; display: block; content: ''; } }

.section-donate .donate_form p { font-size: 16px; margin-top: 0px; line-height: 22px; text-align: center; }

.section-donate .donate_form p.small { font-size: 14px; line-height: 18px; text-align: left; max-width: 323px; margin-left: auto; margin-right: auto; margin-top: 10px; }

@media screen and (max-width: 992px) { .section-donate .donate_form p.small { height: 0; overflow: hidden; } }

.section-donate .donate_form p.bold { font-size: 24px; line-height: 26px; text-align: center; margin-left: auto; margin-right: auto; text-align: center; font-family: 'Eina01-Regular', sans-serif; font-weight: bold; margin-top: 0; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { .section-donate .donate_form p.bold { font-family: 'Eina01-Regular', sans-serif; font-weight: bold; } }

@-moz-document url-prefix() { .section-donate .donate_form p.bold { font-family: 'Eina01-Regular', sans-serif; font-weight: bold; } }

@media screen and (min-width: 993px) { .section-donate .donate_form p.bold { margin-bottom: 10px; } }

.section-donate .donate_form .donate_title { margin-left: auto; margin-right: auto; z-index: 2; position: relative; margin-top: 20px; }

@media screen and (min-width: 993px) { .section-donate .donate_form .donate_title { margin-top: 100px; margin-bottom: 37px; } }

@media screen and (max-width: 992px) { .section-donate .donate_form .donate_title { margin-bottom: 0px; } }

.section-donate .donate_form .donate_img { width: 100%; max-width: 252px; border-radius: 12px; margin: 0 auto; margin-bottom: 30px; }

.section-donate .donate_form:after { position: absolute; }

.section-donate .donate_form .form_contents { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; height: 100%; }

.section-donate .donate_form .thank_contents { margin: auto; max-width: 301px; height: 100%; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }

.section-donate .donate_form .thank_contents p { margin-top: auto; margin-bottom: 20px; text-align: left; line-height: 32px; }

.section-donate .donate_form .thank_contents p.sub-text { margin-top: 10px; font-size: 18px; line-height: 27px; }

.section-donate .donate_form .stripe-form { height: 100%; }

.section-donate .donate_form .stripe-form .form-row { height: 100%; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }

.section-donate .donate_form .stripe-form .form-row div#card-errors { text-align: center; margin-top: 22px; font-size: 14px; color: #f00; }

.section-donate .donate_form .stripe-form .btn-main { min-height: 56px; }

@media screen and (min-width: 993px) { .section-donate .donate_form .stripe-form .btn-main { margin-top: auto; } }

.section-donate .donate_form .stripe-form #card-element { min-height: 43px; }

.section-donate .donate_form .show { -webkit-animation: slidein 0.3s forwards; animation: slidein 0.3s forwards; }

.section-donate .donate_form .form-row { height: 100%; }

.section-donate .donate_form .amount_selector { display: -webkit-box; display: -ms-flexbox; display: flex; max-width: 323px; -ms-flex-wrap: wrap; flex-wrap: wrap; margin: 0 auto; }

.section-donate .donate_form .amount_selector .btn { width: 100%; max-width: calc(33.333333% - 9px); margin-bottom: 13px; margin-right: 13px; padding-top: 0.8333em; padding-bottom: 0.83333em; border-radius: 12px; font-size: 1rem; font-family: 'Eina01-Regular'; font-weight: bold; background-color: #F6F7F8; outline: none; }

@media screen and (max-width: 768px) { .section-donate .donate_form .amount_selector .btn { padding-top: 0.75em; padding-bottom: 0.75em; } }

.section-donate .donate_form .amount_selector .btn:nth-child(3n) { margin-right: 0; }

.section-donate .donate_form .amount_selector .btn:focus { background-color: #0aad0a; color: #fff; }

.section-donate .donate_form .amount_selector .btn.selected { background-color: #0aad0a; color: #fff; }

.section-donate .donate_form .amount_selector .form-control { margin-top: 35px; color: #434343; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { .section-donate .donate_form .amount_selector .form-control { color: #72767E; } }

.section-donate .donate_form .amount_selector .stripe-form .form-control { margin-bottom: 27px; margin-top: 0; }

.section-donate .donate_form .amount_selector.stripe-warp { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }

.section-donate .donate_form .amount_selector.stripe-warp .row { -ms-flex-wrap: nowrap; flex-wrap: nowrap; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; }

.section-donate .donate_form .amount_selector.stripe-warp .col-md-12 { -webkit-box-flex: 0; -ms-flex: 0 0 100%; flex: 0 0 100%; }

.section-donate .donate_form .amount_selector.stripe-warp .col-md-6 { -webkit-box-flex: 0; -ms-flex: 0 0 46%; flex: 0 0 46%; margin-right: 20px; }

.section-donate .donate_form .amount_selector.stripe-warp .col-md-6 + .col-md-6 { margin-right: 0; }

.section-donate .donate_form .amount_selector .custom-card, .section-donate .donate_form .amount_selector .custom-cvc, .section-donate .donate_form .amount_selector .custom-card-date { font-size: 18px; height: 51px; padding-top: 9px; }

.section-donate .donate_form .input-group:last-child { margin-top: auto; }

.section-donate .donate_form .form-control { border-radius: 12px; padding: 13px; padding-top: 16px; font-size: 1rem; font-family: 'Eina01-Regular',sans-serif; height: 50px; line-height: 26px; }

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) { .section-donate .donate_form .form-control { line-height: normal; padding-top: 13px; height: 50px; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { .section-donate .donate_form .form-control { height: 50px; -webkit-box-sizing: border-box; box-sizing: border-box; } }

@media not all and (min-resolution: 0.001dpcm) { @supports (-webkit-appearance: none) and (stroke-color: transparent) { .section-donate .donate_form .form-control { line-height: normal; padding-top: 13px; height: 50px; } } }

.section-donate .donate_form .social-link { margin-left: auto; margin-right: auto; margin-bottom: 30px; }

.section-donate .donate_form .social-link a + a { margin-left: 40px; }

.section-donate .donate_form .btn-main { width: 100%; max-width: 208px; margin: 30px auto 12px; padding: 0.85em; border-radius: 12px; padding-top: 18px; padding-bottom: 18px; margin-top: auto; height: 56px; line-height: 0px; }

.section-donate .donate_form .btn-main.btn-complete { background-color: #D8D8D8; }

@media screen and (max-width: 768px) { .section-donate .donate_form .btn-main { margin-bottom: 12px; max-width: 188px; padding: 0.72em; } }

.pc .section-donate .donate_form .amount_selector .form-control { padding-top: 11px; }

.pc .section-donate .donate_form .amount_selector .custom-card { height: 53px; padding-top: 9px; }

.pc .section-donate .donate_form .btn-main { padding-top: 12px; padding-bottom: 12px; }

.pc .section-donate .donate_form form button.btn.btn-primary.btn-block.mt-4 { padding-top: 12px; padding-bottom: 12px; }

.section-join { padding-top: 70px; padding-bottom: 62.9px; }

@media screen and (max-width: 992px) { .section-join { padding-bottom: 60px; padding-top: 5px; } }

.section-join h4 { margin-top: 0px; font-size: 32px; line-height: 46px; }

@media screen and (max-width: 992px) { .section-join h4 { margin-top: 30px; font-size: 24px; line-height: 32px; } }

.section-join .col { position: relative; }

@media screen and (max-width: 992px) { .section-join .col { margin-bottom: 30px; }
  .section-join .col.last { margin-bottom: 0; } }

.section-join:before { content: ''; width: 100%; height: 60px; background: #a5cdf5; display: none; position: absolute; bottom: 0; }

@media screen and (max-width: 992px) { .section-join:before { height: 31px; } }

.section-join .bottom-img { position: absolute; bottom: -156px; right: 20px; max-width: 643px; z-index: 0; width: 100%; }

@media screen and (max-width: 992px) { .section-join .bottom-img { position: relative; margin: 0 auto; max-width: 260px; bottom: -34px; z-index: 0; width: 100%; right: auto; } }

.section-join img { margin-top: 65px; }

@media screen and (max-width: 992px) { .section-join img { display: none; } }

@media screen and (max-width: 1280px) { .section-join img { width: 100%; } }

@media screen and (min-width: 993px) { .section-join .help_info { margin-top: 0px; } }

.section-join .help_info p { font-family: 'Eina01', sans-serif; font-size: 16px; line-height: 24px; font-weight: 600; }

@media screen and (max-width: 425px) { .section-join .help_info p.learn_link { max-width: 200px; } }

.section-share { padding-bottom: 100px; display: none; }

@media screen and (max-width: 992px) { .section-share { padding-top: 40px; padding-bottom: 50px; } }

.section-share .share_img { border-radius: 12px; width: 100%; max-width: 568px; margin-right: 20px; }

@media screen and (max-width: 992px) { .section-share .share_img { max-width: 100%; margin-right: 0; } }

.section-share .share_info { padding-left: 110px; padding-top: 110px; }

@media screen and (min-width: 993px) { .section-share .share_info p { max-width: 477px; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { .section-share .share_info { max-width: 50%; } }

@media screen and (max-width: 1280px) { .section-share .share_info { padding-top: 10px; padding-left: 60px; } }

@media screen and (max-width: 992px) { .section-share .share_info { padding-left: 0; } }

.footer { position: relative; }

.footer .footer_row .footer_logo img { width: 100%; max-width: 25px; }

@media screen and (min-width: 769px) and (max-width: 992px) { .footer .footer_row .footer_logo { margin-right: auto; } }

.footer .footer_row .footer_logo.footer_main_logo { margin-right: auto; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }

.footer .footer_row .footer_logo.footer_main_logo span { margin-left: 55px; margin-top: auto; margin-bottom: auto; line-height: normal; color: #fff; }

@media screen and (max-width: 992px) { .footer .footer_row .footer_logo.footer_main_logo span { display: none !important; } }

@media screen and (max-width: 992px) { .footer .footer_row .footer_icon { display: none; } }

.footer .footer_row .footer_icon img { width: 100%; max-width: 44px; }

@media screen and (max-width: 992px) { .footer .footer_row { -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; }
  .footer .footer_row .col { text-align: center; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; -webkit-box-flex: 1; -ms-flex: auto; flex: auto; width: auto; } }

.footer .footer_links { z-index: 1; line-height: normal; }

@media screen and (min-width: 993px) { .footer .footer_links { margin-left: auto; margin-top: auto; margin-bottom: auto; } }

.footer .footerlink.active { color: #0bad0a; }

.footer .desclaimers { z-index: 1; display: none; padding-top: 30px; padding-bottom: 30px; position: absolute; background: #0e491f; left: 0; right: 0; bottom: 0; }

@media screen and (max-width: 768px) { .footer .desclaimers { padding-top: 10px; } }

.footer .desclaimers.show-disclaimer { opacity: 1; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-animation: show-disclaimer 0.4s forwards; animation: show-disclaimer 0.4s forwards; }

.footer .desclaimers.hide-disclaimer { -webkit-animation: hide-disclaimer 0.1s forwards; animation: hide-disclaimer 0.1s forwards; }

.footer .desclaimers .container { position: relative; }

.footer .desclaimers .close-chevron { position: absolute; right: 0; top: 0; }

@media screen and (max-width: 1280px) { .footer .desclaimers .close-chevron { right: 15px; } }

@media screen and (max-width: 768px) { .footer .desclaimers .close-chevron { top: 15px; } }

.footer .desclaimers p { color: #fff; margin-top: 0; }

@media screen and (max-width: 768px) { .footer .desclaimers p { font-size: 12px; line-height: 16px; margin-top: 10px; } }

.footer .desclaimers .footer_logo { margin-right: 50px; }

.footer .desclaimers .disclaimer-content { max-width: 780px; }

.footer .desclaimers .row { -ms-flex-wrap: nowrap; flex-wrap: nowrap; }

.footer .discaimer-overlay { position: fixed; display: none; top: 0; left: 0; right: 0; bottom: 55px; background-color: transparent; }

#loader { display: none; padding-top: 18px; margin: 0 auto; width: 30px; }

@-webkit-keyframes slidein { 0% { -webkit-transform: translateX(100%); transform: translateX(100%); }
  100% { -webkit-transform: translateX(0%); transform: translateX(0%); } }

@keyframes slidein { 0% { -webkit-transform: translateX(100%); transform: translateX(100%); }
  100% { -webkit-transform: translateX(0%); transform: translateX(0%); } }

@-webkit-keyframes show-disclaimer { 0% { bottom: 0; }
  100% { bottom: 55px; } }

@keyframes show-disclaimer { 0% { bottom: 0; }
  100% { bottom: 55px; } }

@-webkit-keyframes hide-disclaimer { 0% { bottom: 55px; }
  100% { opacity: 0;
    bottom: 0px; } }

@keyframes hide-disclaimer { 0% { bottom: 55px; }
  100% { opacity: 0;
    bottom: 0px; } }

.image-reveal { position: absolute; top: -5px; left: -2px; right: -2px; bottom: 0; background: #fff; display: none; }

@-webkit-keyframes imgReveal { 0% { top: -5px; }
  100% { top: 100%; } }

@keyframes imgReveal { 0% { top: -5px; }
  100% { top: 100%; } }

.imgReveal { -webkit-animation: imgReveal forwards; animation: imgReveal forwards; -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }

.slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img { -webkit-transform: translate3d(0, 0, 0); transform: none; }

.wow { visibility: hidden; }

ul.form_stage { display: -webkit-box; display: -ms-flexbox; display: flex; margin: 0 auto; list-style: none; padding: 0; }

ul.form_stage li { width: 8px; height: 8px; background-color: #C4C4C4; border-radius: 8px; position: absolute; bottom: -10px; }

ul.form_stage li.active { background-color: #0aad0a; }

ul.form_stage li + li { margin-left: 6px; }

.steps { position: absolute; bottom: -18px; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); /* Mark the steps that are finished and valid: */ }

.steps .step { height: 8px; width: 8px; margin: 0 2px; background-color: #bbbbbb; border: none; border-radius: 50%; display: inline-block; opacity: 0.5; }

.steps .step.active { opacity: 1; background-color: #4CAF50; }

.steps .step.finish { background-color: #4CAF50; }

.img-contain { opacity: 0; -webkit-transform: scale3d(0.8, 0.8, 0.8); transform: scale3d(0.8, 0.8, 0.8); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { .img-contain { opacity: 1; -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); } }

.slick-active .zoomInCustom { -webkit-animation-delay: 0.1s; animation-delay: 0.1s; }

.slick-active + .slick-active .zoomInCustom { -webkit-animation-delay: 0.2s; animation-delay: 0.2s; }

.slick-active + .slick-active + .slick-active .zoomInCustom { -webkit-animation-delay: 0.3s; animation-delay: 0.3s; }

.slick-active + .slick-active + .slick-active + .slick-active .zoomInCustom { -webkit-animation-delay: 0.4s; animation-delay: 0.4s; }

.zoomInCustom { opacity: 0; -webkit-transform: scale3d(0.8, 0.8, 0.8); transform: scale3d(0.8, 0.8, 0.8); -webkit-animation: zoomInCustom 1s forwards; animation: zoomInCustom 1s forwards; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { .zoomInCustom { opacity: 1; -webkit-animation: none !important; animation: none !important; -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); } }

@-webkit-keyframes zoomInCustom { 0% { opacity: 0;
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
            transform: scale3d(0.8, 0.8, 0.8); }
  100% { opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

@keyframes zoomInCustom { 0% { opacity: 0;
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
            transform: scale3d(0.8, 0.8, 0.8); }
  100% { opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

.section-intro .row .col .intro_slide li { margin: 0 auto; }
/*# sourceMappingURL=main.css.map */