body{

    &.stickyOn{
        margin-top: 50px;
        @media screen and (min-width: $sm-tab + 1) {
            margin-top: 75px;
        }
    }

    &.page-interim{
        overflow-y: hidden;
        max-height: 100vh;
        .interim_link{
            text-decoration: underline;
        }
    }
}


.header{
    padding-top: 13px; 
    padding-bottom: 14px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    background: #fff;
    @media screen and (max-width: $sm-tab) {
        padding-top: 8px;
        padding-bottom: 8px;
   }
    .btn-main{
        padding: 0.85em 1em;
        max-width: 178px;
        margin-left: auto;
        height: 48px;
        line-height: 48px;
        border-radius: 8px;
        // @media (-webkit-min-device-pixel-ratio: 1.25),
        // (min-resolution: 120dpi) {
        //     padding-top: calc(0.85rem + 2px);
        // }

        @media screen and (max-width: $sm-tab){
            height: 34px;
            max-width: 121px;

        }

        
    }
    .container{
        .row{
            .col{
                flex: 0 0 calc(100% - 75px);
                &.logo-col{
                    flex: 0 0 55px;
                    margin-right: 20px;
                     @media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
                        flex        : 0 0 50%;
                        margin-right: 0px;
                    }
                    a{
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }
                    img{
                        width: 42px;
                        margin-top: auto;
                        margin-bottom: 2px;
                        @media screen and (max-width: $sm-tab) {
                            width: 22.36px;
                            height: 24.91px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
            flex-wrap: nowrap;
            flex-direction: row;
        }
    }

    // sticky header

    &.sticky{
            position   : fixed;
            top        : 0;
            left       : 0;
            right      : 0;
            z-index    : 9;
            animation  : sticky 0.7s forwards;
            // padding-top: 8px;
            // padding-bottom: 9px;
        
        // .btn-main{
        //    height: 34px;
        //    max-width: 121px;
        //    font-size: 18px;
           
            
        // }
        // .logo-col{
        //     img{
        //         width: 22.36px;
        //         height: 24.91px;
        //     }
        // }
        
     }

     &.sticky-hide{
         animation : sticky-hide 0.7s forwards;
     }
}

// sticky animation 
@keyframes sticky{
    0%   { 
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes sticky-hide {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-100%);
    }
}

.section{
    padding-top: 120px;
    padding-bottom: 120px;
    @media screen and (max-width: $tab){
        padding-top: 60px;
        padding-bottom: 60px;
    }
    position: relative;
}

// intro section
.section-intro {
    padding-top   : 58px;
    padding-bottom: 0px;
    .container{
        @media screen and (max-width: $tab){
            padding: 0;
            h3,h4,h5,p,.img-col{
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
   .row{
       flex-wrap: nowrap;
       .col{
          
                flex     : 0 0 auto;
                @media screen and (min-width: $tab + 1){
                    max-width: 650px;
                }
                

                &.intro_img {
                    position: relative;
                     margin-left: auto;
                    @media screen and (min-width: $tab + 1){
                        max-width: calc(100% - 650px);
                    }
                } 
                &.intro_img_hero{
                    position: relative;
                    width: 100%;
                    margin-left: auto;
                   @media screen and (min-width: $tab + 1){
                         max-width: calc(100% - 650px);
                  }
                }

                .intro_slide{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    position: relative;
                    
                    width: 100%;
                    @media screen and (min-width: $tab + 1){
                        max-width: 478px; 
                        margin-left: auto;
                    }
                    .slick-arrow{
                        opacity: 0;
                        transition: all ease 0.5s;
                        
                    }
                    &:hover{
                        .slick-arrow{
                            opacity: 1;
                        }
                    }
                   li{
                       float: left;
                       display: block;
                       @media screen and (min-width: $tab + 1){
                            max-width: 478px; 
                            
                       }
                       
                       position: relative;
                       .hero_text{
                           position: absolute;
                           background: rgba(22, 22, 22, 0.4);
                           bottom: 30px;
                           padding: 10px 30px 20px;
                           @media screen and (max-width: $sm-tab){
                                padding: 10px 18px 20px;
                           }
                           color: #fff;
                           font-size: 12px;
                           line-height: 18px;
                           font-weight: 600;
                       }
                       img{
                           width: 100%;
                           @media screen and (min-width: $tab + 1){
                                border-radius: 10px;
                            }
                           
                       }
                   }
                }
            
           
       }
   }
    @media screen and (max-width:$tab) {
        padding-top   : 20px;
        padding-bottom: 50px;
    }

    .row {
        @media screen and (max-width:$tab) {
            flex-direction: column;
        }
    }

    p {
        color: #343538;
        @media screen and (min-width:$tab + 1) {
            max-width: 576px;
        }

    }

    .intro_title {
        margin-top: 0px;
        margin-bottom: 25px;
         font-size: 56px;
        line-height: 64px;
        @media screen and (max-width: $sm-tab){
            font-size: 24px;
            line-height: 40px;
            max-width: 425px;
            br{
                display: none;
            }
        }
    }
    .intro_subtitle{
        margin-top: 15px;
        margin-bottom: 45px;
        @media screen and (max-width: $sm-tab) {
            margin-bottom: 30px;
            font-size: 18px;
            line-height: 32px;
        }
    }

    h5{
        margin-bottom: 10px;
        @media screen and (max-width: $sm-tab) {
            margin-bottom: 20px;
            margin-top: 40px;
            font-size    : 24px;
            line-height  : 32px;
        }
    }
    

    .intro-img {
        max-width: 530px;
        width    : 100%;
        margin-bottom: 40px;
    }
    .intro_img{
        display: flex;
        align-items: start;
        
        img{
            width: 100%;
           
            @media screen and (min-width: $tab + 1){
                max-width: 478px;
                margin-left: auto;
                border-radius: 12px;
            }
        }
    }

    .img-col {
        display        : flex;
        align-items    : flex-start;

        @media screen and (max-width:$tab) {
            justify-content: center;
            margin-bottom  : 30px;
        }
    }
}

.section-buildings{
    padding-top: 84px;
    padding-bottom: 0px;
    @media screen and (max-width: $sm-tab){
        display: none;
    }
    .col{
        display: flex;
        align-items: flex-end;
    }
    img.buildings{
        max-width: 975px;
        margin: 0 auto;
    }
}


// windows custom class for body
.pc .section-donate .donate_form .amount_selector .form-control {
    padding-top: 11px;

}

.pc .section-donate .donate_form .amount_selector .custom-card{
    height: 53px;
    padding-top: 9px;
}
.pc .section-donate .donate_form .btn-main{
    padding-top: 12px;
    padding-bottom: 12px;
}
.pc .section-donate .donate_form form button.btn.btn-primary.btn-block.mt-4{
    padding-top: 12px;
    padding-bottom: 12px;
}



#loader {
    display    : none;
    padding-top: 18px;
    margin     : 0 auto;
    width: 30px;
}
    

@keyframes slidein{
    0%{
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes show-disclaimer {
    0% {
        bottom: 0;
    }

    100% {
        bottom: 55px;
    }
}

@keyframes hide-disclaimer {
    0% {
        bottom: 55px;
    }

    100% {
        opacity: 0;
        bottom: 0px;
    }
}

.image-reveal{
    position: absolute;
    top: -5px;
    left: -2px;
    right: -2px;
    bottom: 0;
    background: #fff;
    display: none;
}
@keyframes imgReveal {
    0% {
        top: -5px;

    }

    100% {
        top: 100%;

    }    
}

.imgReveal {
    animation: imgReveal forwards;
    animation-timing-function : cubic-bezier(0.5, 0, 1, 0.5)
}



.slick-slider .slick-list,
.slick-track,
.slick-slide,
.slick-slide img {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform   : translate3d(0, 0, 0);
    -ms-transform    : none;
    -o-transform     : translate3d(0, 0, 0);
    transform        : none;
}

.wow{
   visibility: hidden;
}

ul.form_stage {
    display: flex;
    margin : 0 auto;
    list-style: none;
    padding: 0;
    li{
        width: 8px;
        height: 8px;
        background-color: #C4C4C4;
        border-radius: 8px;
        position: absolute;
        bottom: -10px;

        &.active{
            background-color: $green_main;
        }
        & + li{
            margin-left: 6px;
        }
    }
}

.interim{
    height: 100vh;
    max-height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    .overlay{
        background-color: rgba(66, 66, 66, 0.8);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .interim-container{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        background-color: #fff;
        border-radius: 18px;
        padding: 70px 90px;
        width: 100%;
        max-width: 1170px;
        @media screen and (max-width: 1200px){
            max-width: calc(100% - 60px);
        }

        @media screen and (max-width: 992px){
            padding: 35px 40px;
            max-width: calc(100% - 32px);
            
        }
        @media screen and (max-width: 768px){
            width: calc(100% - 32px);
            max-width: 360px;
        }
        @media screen and (max-width: 360px){
            padding: 10px 30px;
        }
        h3.interim_title{
            font-size: 38px;
            line-height: 58px;
            font-weight: 400;
            margin-top: 10px;
            span{
                font-weight: 900;
            }
            @media screen and (max-width: 992px){
                font-size: 28px;
                line-height: 34px;
                margin-left: auto;
                margin-right: auto;
            }
            @media screen and (max-width: 360px){
                font-size: 24px;
                line-height: 32px;
                
            }
        }

        .row{
            display: flex;
            .col{
                flex: 0 0 50%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
            }

            @media screen and (max-width: 768px){
                flex-direction: column;
            }
        }

        .logo_interim{
            text-align: left;
            margin-top: 50px;
            &.mobile{
                @media screen and (min-width: 769px){
                    display: none;
                   
                }
                margin: 0 auto;
                margin-top: 45px;
                margin-bottom: 15px;
                @media screen and (max-width: 360px){
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
            &.desktop{
                width: 100%;
                max-width: 216px;
                @media screen and (max-width: 992px){
                    max-width: 172px;
                }
                @media screen and (max-width: 768px){
                    display: none;

                }
            }
        }
        .cart_interim{
            max-width: 276px;
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
            @media screen and (max-width: 992px){
                margin-right: auto;
                max-width: 165px;
                margin-top: 30px;
            }

            @media screen and (max-width: 360px){
                margin-top: 10px;
            }
        }
    }
}