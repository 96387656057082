.w-100{
    width: 100%;
    flex: 0 0 100% !important;
}
.text-center{
    text-align: center;
}
.d-none{
    display: none !important;
}
.d-flex{
    display: flex;
}
.hide-xs{
    @media screen and (max-width: $sm-tab){
        display: none !important;
    }
}
.show-xs{
    @media screen and (min-width: $sm-tab) {
        display: none !important;
    }
}
.hide-md {
    @media screen and (max-width: $tab) {
        display: none !important;
    }
}
.hide-tab-above{
    @media screen and (min-width: $tab + 1){
        display: none !important;
    }
}
.hide-lg{
    @media screen and (min-width: $desktop + 1) {
    display: none !important;
    }
}

