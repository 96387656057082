body,html{
    margin: 0;
    padding: 0;
    overflow-X: hidden;
}
div{
    box-sizing: border-box;
}
img{
    border: none;
}

.container{
    width: 100%;
    max-width: 1200px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    @media screen and (max-width: $desktop){
        padding-left: 16px;
        padding-right: 16px;
    }
    margin: 0 auto;
    .row{
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: $tab){
             flex-direction: column;
        }
    }
    .col{
        flex: 0 0 50%;
        @media screen and (max-width: $tab){
            flex: 0 0 100%;
            width: 100%;
        }
    }
}
.slick-list {
    position: relative;
    overflow: hidden;
    display : block;
    margin  : 0;
    padding : 0;
    max-width: 1140px;
}

.slick-prev:before,
.slick-next:before{
    content: '';
}

.slick-prev,
.slick-next {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &:hover, &:focus {
        background-repeat  : no-repeat;
        background-position: center;
        background-size    : contain; 
     }
}
.slick-prev{
    background-image: url(../images/slick-prev.svg);
    &:hover, &:focus{
        background-image: url(../images/slick-prev.svg);
    }
}
.slick-next {
    background-image: url(../images/slick-next.svg);
     &:hover, &:focus {
     background-image: url(../images/slick-next.svg);
 }
}