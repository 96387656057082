// footer{
//     background: $green_darker;
//     &.footer{
//         padding-top: 25px;
//         padding-bottom: 25px;
//          p{
//             margin-bottom: 0;
//         }
//         @media screen and (max-width: $tab){
//             padding-top: 23px;
//             padding-bottom: 23px;
//             p{
//                  margin-bottom: auto;
//                 margin-top   : auto;
//                 margin-left  : auto;
//                 margin-right : 0;
//             }
//         }
//         .footer_links{
//             color: #fff;
//             font-family: 'Eina01-Regular', sans-serif;
//             font-size: 16px;
//             @media screen and (max-width: $sm-tab){
//                 font-size: 10px;
//                 line-height: 14px;
                
//             }
//              a{
//                 color: #fff;
//             }
//         }
//         .col-links{
//             display: flex;
//             align-items: center;
//             justify-content: right;
//         }
//     }
// }

//Footer revamp style

.footer-main-wrapper {
    hr {
        border-top: 1px solid #BDBDBD;
        margin-top:0;
        margin-bottom:38px;
    }
    .mobile-version {
        display: none;
    }
    .text-for-disabled {
        margin-top: 16px;
        margin-bottom: 30px;
        font-size: 12px;
        line-height: 140.62%;
        color: #343538;
        .contact-no {
            color: #343538;
        }
    }
    .copyright {
        margin-top: 28.1px;
        margin-bottom:20px;
        small {
            margin-right: 32px;
            a {
            color: #72767E;
            font-size: 10px;
            font-weight: 600;
            }

        }
    }
    .social-media-icon-sec {
        a {
            img {
                margin-right: 14.71px;
            }
        }
    }
    .footer-col-links {
        max-width:140px;
        margin-right: 60px;
    }
    .footer-col {
        max-width: 280px;
        margin-right:80px;
    }
    .essential-nav {
        dt {
            margin-bottom:18px;
            font-weight: 700;
            color:#343538;
        }
        dd {
            margin-bottom: 16px;
            margin-left:0px;
            a{
                font-size:14px;
                color: #424242;
                font-weight: 600;
            }
        }

    }
    .footer-nav {
        list-style: none;
        padding:0;
        .footer-nav-link{
            a {
                color: #424242;
                font-weight: 600;
            }
            margin-left:0px;
            margin-bottom:17px;
        }
        .nav-link-last {
            margin-bottom:30px;
        }
    }

    @media(max-width:1191px) {
        .footer-col-links {
            max-width:130px;
            margin-right: 40px;
        }
        .footer-col {
            max-width: 280px;
            margin-right:80px;
        }
    }
    @media(max-width:1071px) {
        .copyright {
            margin-bottom:0px;
        }
        .text-for-disabled {
            margin-top:0px;
            font-size: 10px;
        }
        .footer-col-links {
            max-width:130px;
            margin-right: 30px;
        }
        .footer-col {
            max-width: 280px;
            margin-right:40px;
        }
    }
    @media(max-width:$tab) {
        .text-for-disabled {
            color: #424242;
        }
        .copyright {
            margin-top:0px;
        }
        dl {
            margin:0px 0px 50px 0px;
        }
        .footer-row-copyright {
            padding-left:20px;
        }
        small {
            width: 100%;
            margin-bottom:18px;        
            display: block;
            a{
                color: #343538 !important;
            }
        }
       .copyright {
            margin-bottom:0px;
        }
        .small-last-child {
            margin-bottom: 10px !important;
        }
        .footer-col {
            max-width:100%;
        }
        .footer-nav, .footer-col-links {
            margin-left: 20px;
            margin-right:0px;
        }
        .footer-nav-link{
            a {
            font-size: 14px;
            }
        }
        dt {
            font-size:14px;
            margin-bottom:9px !important;
        }
        dd {
            margin-bottom:14px !important;
            a{
                font-size:12px !important;
            }
        }
        .mobile-version {
            display: inline-block;
        }
        .social-media-icon-sec {
            float: right;
            margin-top: 8px;
        }
        .desktop-version {
            display: none;
        }
        .footer-row {
            flex-direction: inherit;
        }
        .footer-logo {
            img {
                width:111px;
            }
        }
    }
}
