
// @font-face {
//     font-family: 'Eina01-Regular';
//     src        : url('../fonts/eina/Eina01-Light.eot');
//     src        : url('../fonts/eina/Eina01-Light.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/eina/Eina01-Light.woff2') format('woff2'),
//         url('../fonts/eina/Eina01-Light.woff') format('woff'),
//         url('../fonts/eina/Eina01-Light.svg#Eina01-Light') format('svg');
//     font-weight: 300;
//     font-style : normal;
// }

@font-face {
    font-family: 'Eina01-Regular';
    src        : url('../fonts/eina/Eina01-SemiBold.eot');
    src        : url('../fonts/eina/Eina01-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/eina/Eina01-SemiBold.woff2') format('woff2'),
        url('../fonts/eina/Eina01-SemiBold.woff') format('woff'),
        url('../fonts/eina/Eina01-SemiBold.svg#Eina01-SemiBold') format('svg');
    font-weight: 600;
    font-style : normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina01-Regular';
    src        : url('../fonts/eina/Eina01-Regular.eot');
    src        : url('../fonts/eina/Eina01-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/eina/Eina01-Regular.woff2') format('woff2'),
        url('../fonts/eina/Eina01-Regular.woff') format('woff'),
        url('../fonts/eina/Eina01-Regular.svg#Eina01-Regular') format('svg');
    font-weight: normal;
    font-style : normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina01-Regular';
    src        : url('../fonts/eina/Eina01-Bold.eot');
    src        : url('../fonts/eina/Eina01-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/eina/Eina01-Bold.woff2') format('woff2'),
        url('../fonts/eina/Eina01-Bold.woff') format('woff'),
        url('../fonts/eina/Eina01-Bold.svg#Eina01-Bold') format('svg');
    font-weight: bold;
    font-style : normal;
    font-display: swap;
}
  
  

html{
    font-size: 18px;
    font-family: 'Eina01-Regular', sans-serif;
    color: $text;
     -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing : antialiased;
        font-smoothing         : antialiased;
}

@-moz-document url-prefix() {
    body {
        font-weight: lighter !important;
    }
}


p{
    font-size: 18px;
    line-height: 26px;
}

h1,h2,h3,h4,h5{
    font-family: 'Eina01-Regular',sans-serif;
    font-weight: bold;
    margin-bottom: 1em;
    margin-top: 0.75em;
    @media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
       font-family: 'Eina01-Regular', sans-serif;
        font-weight: bold;
       letter-spacing: -0.01em;
    }
    @-moz-document url-prefix() {
        font-family: 'Eina01-Regular',sans-serif;
        font-weight: bold;
        letter-spacing: -0.01em;
    }
}
h1{
    font-size: 68px;
    line-height: 84px;
    @media screen and (max-width: $sm-tab - 1){
        font-size: 31px;
        line-height: 42px;
    }
}

h3{
    font-size: 50px;
    line-height: 72px;
    @media screen and (max-width: $sm-tab){
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

h4{
    font-size: 32px;
    line-height: 40px;
    @media screen and (max-width: $sm-tab){
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
h5 {
    font-size  : 24px;
    line-height: 32px;

    @media screen and (max-width: $sm-tab) {
        font-size  : 1rem;
        line-height: 1.5rem;
    }
}
.bold, strong, b{
    font-weight: bold;
}
a{
    color: $green_main;
    text-decoration: none;
    &:hover{
        color: $green_dark;
    }
}