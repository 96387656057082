/* The Modal (background) */
.modal {
    display: none;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; 
    overflow: auto;
    background: rgba(114, 118, 126, 0.8);
  }

  .modal-content {
    position: relative;
    background-color: #fefefe;
    padding: 25px 47px ;
    border-radius: 18px;
    box-shadow: 0px 4px 8px #72767E;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    max-width: 772px;
    margin:0 auto;
    position: absolute;
    left: 23px;
    right: 20px;
    top: 50%; 
    -webkit-transform:translateY(-50%);
    -moz-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    -o-transform:translateY(-50%);
    transform:translateY(-50%);
  }

.modal-close-btn {
    width:15px;
}
.close {
    float: right;
    background-image: url('../images/close-modal.svg');
    background-repeat: no-repeat;
    width:15px;
    height:15px;
}
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-header {
      margin-bottom:18px;
      h2 {
        font-size: 15px;
        color: #343538;
        margin:0px;
      }

  }
  
  .modal-body {
    p {
        font-size: 15px;
        line-height: 22px;
        color: #343538;
        margin:0px;
    }
}
  
@media(max-width:$tab) {
    .modal-content {
        padding-left: 25px;
        padding-right: 23px;
    }
}

// body.modal-open{
//     overflow: hidden;
// }
html.modal-open{
  overflow: hidden;
}